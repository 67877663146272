import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { PaymentTicket, TicketActionResponse } from '../core/_models'
import { KTIcon } from '../../../../_metronic/helpers'
import { convertUTCtoMSK, formatSum } from '../../../../utils'
import {
  finishTicketAPI,
  cancelTicketAPI,
  ticketFileGet,
  ticketAppendMerchantGet,
} from '../core/_requests'
import { ROLE, useAuth } from '../../auth'
import { ETicketStatus } from '../../../../types/enums'
import { openFile } from '../../../../utils/openFile'
import MerchantFilter from '../../../common/filter/MerchantFilter'
import { IsGranted } from '../../auth/PrivateRoute'
import { getCardNumberMask } from '../../../../utils/getCardNumber'
import { Merchant } from '../../../common/core/_models'

const AppSwal = withReactContent(Swal)

const ActiveTicketItem = (props: {
  ticket: PaymentTicket
  removeTicketFromList: (token: string) => void
  updateTicketList: () => void
  setShowAlert: Dispatch<SetStateAction<boolean>>
  merchants?: Array<Merchant>
}) => {
  const amountInputRef = useRef<HTMLInputElement>(null)
  const [amount, setAmount] = useState('')
  const ticket = useState(Object.assign(props.ticket, {}))[0]
  const { currentUser } = useAuth()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const cancelTicket = async () => {
    AppSwal.fire({
      title: 'Вы точно хотите отменить операцию?',
      showCancelButton: true,
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, отменить',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await cancelTicketAPI(ticket.token, currentUser)

          if (response.data && response.data.success) {
            handleCancelFinishResponse(response)
          } else {
            if (response.status === 400) {
              AppSwal.fire('', 'Невозможно отменить тикет', 'warning')
            }

            if (response.status === 422) {
              AppSwal.fire('', 'Тикет не может быть отменен', 'warning')
            }

            return false
          }
        } catch (err) {
          console.log('error catched: ', err)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        AppSwal.fire({
          title: `Операция отменена успешно!`,
          timer: 1500,
        })
      }
    })
  }

  const finishTicket = async (externalUserId: string = '') => {
    let response = await finishTicketAPI(ticket.token, externalUserId, amount, currentUser)

    if (response.status === 400) {
      Swal.fire('', 'Невозможно завершить тикет', 'warning')
    }

    if (response.status === 422) {
      Swal.fire('', 'Тикет не может быть завершен', 'warning')
    }

    handleCancelFinishResponse(response)
  }

  const handleCancelFinishResponse = (response: AxiosResponse<TicketActionResponse>) => {
    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.ticket) {
      if (response.data.ticket.status !== ETicketStatus.ACTIVE) {
        props.removeTicketFromList(ticket.token)
      }
    }
  }

  const onChangeMerchant = async (selected: any) => {
    await ticketAppendMerchantGet(ticket.token, selected.value[0])

    props.updateTicketList()
  }

  const onFileClick = async () => {
    const res = await ticketFileGet(ticket.bill)
    openFile(res.data)
  }

  const handleCopyToClipboard = (e: MouseEvent<HTMLSpanElement>) => {
    const span = e.target as HTMLElement
    navigator.clipboard.writeText(
      ticket?.paymentMethod?.type === 'MC/VISA/MIR P2P RUB'
        ? span.innerText.split(' ').slice(0, -1).join('')
        : span.innerText
    )
    props.setShowAlert(true)
    setTimeout(() => {
      props.setShowAlert(false)
    }, 1400)
  }

  return (
    <>
      <tr>
        <td className='w-300px'>
          <p>{ticket.token}</p>
        </td>
        <td className='w-100px'>{ticket?.transactionId}</td>
        <td>{convertUTCtoMSK(ticket.createdAt)}</td>
        {IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
          currentUser
        ) && (
          <td>
            {ticket.amount ? (
              `${formatSum(ticket.externalUserApproximateAmount || 0)} / ${ticket.amount}`
            ) : (
              <input
                className={`border border-${
                  parseFloat(amount) <= 0 || amount === '' ? 'danger' : 'success'
                }`}
                ref={amountInputRef}
                type='number'
                min={0.1}
                required
                value={amount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                placeholder={`${formatSum(ticket.externalUserApproximateAmount || 0)}`}
                style={{ width: '100px', color: '#000000', padding: '4px' }}
              />
            )}
          </td>
        )}
        <td>{ticket?.lastExternalTransaction?.paymentAdapterName}</td>
        <td
          className='cursor-pointer position-relative'
          onClick={handleCopyToClipboard}
          onMouseEnter={() => setShowTooltip((prev) => !prev)}
          onMouseLeave={() => setShowTooltip((prev) => !prev)}
        >
          {showTooltip && ticket?.paymentMethod?.type === 'CRYPTO' && (
            <div
              style={{
                zIndex: 20,
                position: 'absolute',
                top: '5px',
                left: '10px',
              }}
              className='badge bg-success text-white'
            >
              <p>{ticket?.lastExternalTransaction?.requisite || '-'}</p>
            </div>
          )}
          <span>
            {ticket?.paymentMethod?.type === 'CRYPTO'
              ? ticket?.lastExternalTransaction?.requisite?.length > 16
                ? ticket?.lastExternalTransaction?.requisite.slice(0, 15) + '...'
                : ticket?.lastExternalTransaction?.requisite || '-'
              : ticket?.lastExternalTransaction?.requisite || '-'}
          </span>
          {ticket?.paymentMethod?.type !== 'CRYPTO' ? (
            <>
              <span className='separator' />
              <span>{ticket?.lastExternalTransaction?.bank?.toUpperCase() || '-'}</span>
            </>
          ) : (
            <></>
          )}
          {ticket?.paymentMethod?.type === 'CRYPTO' && (
            <>
              <span className='separator' />
              <span>{ticket?.paymentCurrency || '-'}</span>
            </>
          )}
        </td>
        <td>{ticket.sms ? ticket.sms.text : 'смс не поступило'}</td>
        {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
          <td>
            {ticket.merchant?.title || (
              <MerchantFilter
                changeSelect={onChangeMerchant}
                value={['']}
                preloadData={props.merchants}
              />
            )}
          </td>
        )}
        <td className='text-end'>
          {IsGranted(
            [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
            currentUser
          ) && (
            <>
              {(ticket.source.value === 'MERCHANT' && ticket.status === 'WAITING_FOR_REQUISITE') ||
              (ticket.source.value === 'SMS' && ticket.status === 'WAITING_FOR_MERCHANT') ? null : (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-4'
                  onClick={() => {
                    if (ticket.amount || parseFloat(amount) > 0) {
                      finishTicket()
                    } else {
                      amountInputRef?.current?.focus()
                    }
                  }}
                >
                  <KTIcon className='fs-1' iconName='check-circle' />
                </button>
              )}
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 fs-6'
                onClick={() => cancelTicket()}
              >
                <KTIcon className='fs-1' iconName='cross-circle' />
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  )
}

export default ActiveTicketItem
