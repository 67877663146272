import { IReportTicket } from '../ReportsPage.types'

export interface IReportTicketListObj {
  [token: string]: IReportTicket
}

export const prepareTicketsToObject = (tickets: IReportTicket[]): IReportTicketListObj => {
  let object: IReportTicketListObj = {}

  tickets.forEach((item) => {
    if (item.status === 'DECLINED' || item.status === 'COMPLETED' || item.status === 'EXPIRED') {
      object[item.token] = item
    }
  })

  return object
}
