import { ChangeEvent, useEffect, useRef, useState } from 'react'
import DateTimeRangeFilter, { DateFilterObject } from '../../../common/filter/DateTimeRangeFilter'
import { SelectData } from '../../../common/filter/models'
import CashBoxFilter, {
  selectType as cashBoxSelectType,
} from '../../../common/filter/CashBoxFilter'
import MerchantFilter, {
  selectType as merchantSelectType,
} from '../../../common/filter/MerchantFilter'
import PaymentTicketStatusesFilter, {
  selectType as statusesSelectType,
} from '../../../common/filter/PaymentTicketStatusesFilter'
import PaymentTicketProcessTypeFilter, {
  selectType as processTypeSelectType,
} from '../../../common/filter/PaymentTicketProcessTypeFilter'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { convertToUTCByMSK } from '../../../../utils'
import { EPaymentMethods } from '../../../../types/enums'
import PaymentMethodsFilter, {
  selectType as paymentMethodType,
} from '../../../common/filter/PaymentMethodsFilter'
import TicketTypeFilter, {
  selectType as ticketTypeFilterType,
} from '../../../common/filter/TicketTypeFilter'

const FinishedTicketFilter = ({
  initialFilter,
  updateFilters,
  callback,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (param1?: boolean, param2?: boolean) => void
}) => {
  const { currentUser } = useAuth()
  const [searchDebounce, setSearchDebounce] = useState<any>(null)
  const [filterBy, setFilterBy] = useState<string>('byCreatedDate')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchMerchantRef = useRef<HTMLInputElement>(null)

  const updateTime = (filterData: DateFilterObject) => {
    updateFilters({
      ...initialFilter,
      dateStart: filterData.dateStart,
      dateEnd: filterData.dateEnd,
    })
  }

  const onFilterByChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      createdDateStart,
      createdDateEnd,
      completionDateStart,
      completionDateEnd,
      dateStart,
      dateEnd,
      ...rest
    } = initialFilter
    setFilterBy(e.target.value)

    if (e.target.value === 'byCreatedDate') {
      updateFilters({
        ...rest,
        dateStart,
        dateEnd,
        createdDateStart: convertToUTCByMSK(dateStart),
        createdDateEnd: convertToUTCByMSK(dateEnd),
      })
    } else {
      updateFilters({
        ...rest,
        dateStart,
        dateEnd,
        completionDateStart: convertToUTCByMSK(dateStart),
        completionDateEnd: convertToUTCByMSK(dateEnd),
      })
    }
  }

  const onChangeSelect = (selectData: SelectData) => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }

    if (searchMerchantRef.current) {
      searchMerchantRef.current.value = ''
    }

    const filterObject = { ...initialFilter }

    switch (selectData.type) {
      case cashBoxSelectType:
        if (!selectData.value.length) {
          delete filterObject.cashBoxId
        } else {
          filterObject.cashBoxId = selectData.value
        }

        break
      case ticketTypeFilterType:
        if (!selectData.value.length) {
          delete filterObject.source
        } else {
          filterObject.source = selectData.value
        }

        break
      case merchantSelectType:
        if (!selectData.value.length) {
          delete filterObject.merchantToken
        } else {
          filterObject.merchantToken = selectData.value
        }

        break
      case statusesSelectType:
        filterObject.status = selectData.value

        break
      case processTypeSelectType:
        if (!selectData.value.length) {
          delete filterObject.ticketProcessType
        } else {
          filterObject.ticketProcessType = selectData.value
        }

        break
      case paymentMethodType:
        if (!selectData.value.length) {
          delete filterObject.paymentMethod
        } else {
          filterObject.paymentMethod = selectData.value
        }

        break
    }

    updateFilters(filterObject)
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce)
    const newTimer = setTimeout(
      () =>
        updateFilters({
          ...initialFilter,
          ...(e.target.value && { ticketToken: e.target.value }),
        }),
      500
    )
    setSearchDebounce(newTimer)
  }

  const onSearchMerchantChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce)
    const newTimer = setTimeout(
      () => {
        updateFilters({
          ...initialFilter,
          ...(e.target.value && { transactionId: e.target.value }),
        })
      },
      500
    )
    setSearchDebounce(newTimer)
  }

  return (
    <div className='row'>
      <div className='col-lg-8'>
        <div className='row mb-7'>
          <div className='col'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                id='by-created-date-radio'
                className='form-check-input'
                type='radio'
                value='byCreatedDate'
                name='report-sort-type-radio'
                onChange={onFilterByChange}
                checked={filterBy === 'byCreatedDate'}
              />
              <label className='form-check-label' htmlFor='by-created-date-radio'>
                По дате создания
              </label>
            </div>
          </div>
          <div className='col'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                id='by-completion-date-radio'
                className='form-check-input'
                type='radio'
                value='byCompletionDate'
                name='report-sort-type-radio'
                onChange={onFilterByChange}
                checked={filterBy === 'byCompletionDate'}
              />
              <label className='form-check-label' htmlFor='by-completion-date-radio'>
                По дате закрытия
              </label>
            </div>
          </div>
        </div>
        <DateTimeRangeFilter filter={initialFilter} updateTime={updateTime} />
      </div>
      <div className='d-flex flex-column flex-sm-row flex-lg-column flex-xxl-row col-lg-4 gap-4 mt-4 mt-lg-0 align-items-md-center'>
        <button className='btn btn-success' onClick={() => callback()}>
          Фильтровать
        </button>

        <button
          className='btn btn-primary'
          onClick={() => {
            setFilterBy('byCreatedDate')
            if (searchInputRef.current) {
              searchInputRef.current.value = ''
            }

            if (searchMerchantRef.current) {
              searchMerchantRef.current.value = ''
            }

            callback(true, true)
          }}
        >
          Сбросить фильтр
        </button>
      </div>
      <div className='col-lg-6 mt-8'>
        <div className='position-relative me-md-2'>
          <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle ms-5'>
            <i className='fa fa-search'></i>
          </span>
          <input
            ref={searchInputRef}
            type='text'
            className='form-control form-control-solid ps-10 pt-3 pb-2'
            onChange={onSearchChange}
            placeholder='Поиск по "ticketToken": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          />
        </div>
        <div className='position-relative me-md-2 pt-5'>
          <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle ms-5'>
            <i className='fa fa-search'></i>
          </span>
          <input
            ref={searchMerchantRef}
            type='text'
            className='form-control form-control-solid ps-10 pt-3 pb-2'
            onChange={onSearchMerchantChange}
            placeholder='Поиск по "Мерчант ID": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          />
        </div>
      </div>
      <div className='col-lg-6'>
        <div className='row'>
          {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) ? (
            <div className='col-lg-6 mt-8'>
              <CashBoxFilter changeSelect={onChangeSelect} value={initialFilter.cashBoxId} />
            </div>
          ) : null}
          <div className='col-lg-6 mt-8'>
            <TicketTypeFilter changeSelect={onChangeSelect} value={initialFilter.source} />
          </div>
        </div>
      </div>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) ? (
        <div className='col-lg-4 mt-10'>
          <MerchantFilter changeSelect={onChangeSelect} value={initialFilter.merchantToken} />
        </div>
      ) : null}
      {IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
        currentUser
      ) ? (
        <div className='col-lg-4 mt-10'>
          <PaymentMethodsFilter value={initialFilter.paymentMethod} changeSelect={onChangeSelect} />
        </div>
      ) : null}
      <div className='col-lg-4 mt-10'>
        <PaymentTicketStatusesFilter
          changeSelect={onChangeSelect}
          isMultiple={true}
          value={initialFilter.status}
        />
      </div>
    </div>
  )
}

export default FinishedTicketFilter
